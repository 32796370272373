@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'cerebriSans';
  src: url('../fonts/CerebriSans-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Add more @font-face declarations for other weights and styles if needed */

:root {
  --font-cerebri-sans: 'cerebriSans', sans-serif;
}

body {
  font-family: var(--font-cerebri-sans);
}

a,
input,
button {
  @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-50 dark:focus-visible:ring-neutral-600 dark:focus-visible:ring-offset-neutral-900;
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding: 0;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1393px;
  }
}

.fp-social-icons {
  display: inline-block;
  margin-bottom: 15px;
}

.fp-social-icons a {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  margin-left: 5px;
  text-decoration: none;
}

.fp-social-icons a:before {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 39px;
  line-height: 40px;
  font-family: 'lumaIcons';
  vertical-align: top;
  display: inline-block;
  font-weight: 400;
  overflow: hidden;
}

.fp-social-icons a:hover:before {
  color: #35823c;
}

.fp-social-icons .fp-insta:before {
  content: '\e914';
}

.fp-social-icons .fp-twitter:before {
  content: '\e91e';
}

.fp-social-icons .fp-facebook:before {
  content: '\e91d';
}

.fp-social-icons .fp-gplus:before {
  content: '\e918';
}

.fp-social-icons .fp-youtube:before {
  content: '\e919';
}

.tick-mark:after {
  content: '\e610';
  font-family: 'lumaIcons' !important;
  font-size: 14px;
  color: #35823c;
  padding: 0 25px;
}

.cross-mark::after {
  content: '\e616';
  font-family: 'lumaIcons' !important;
  font-size: 14px;
  color: #dd3646;
  padding: 0 25px;
}

footer a:hover {
  color: #35823c;
  text-decoration: underline;
}

@layer base {
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
  html {
    color-scheme: light !important;
  }
  /* ... add more heading styles as needed ... */
}
.builder-custom-code li,
.builder-text li {
  list-style-type: disc;
}
#affiliate-menu li {
  list-style-type: none;
}
.builder-custom-code ul,
.builder-text ul {
  padding-left: 15px;
}

.builder-custom-code li ul li,
.builder-text li ul li {
  list-style-type: circle !important;
}

.affiliate-program h3 {
  text-align: center;
  color: #35823c;
  font-weight: 600;
}

.affiliate-program h5 {
  text-align: center;
  font-weight: 700;
  line-height: normal;
}

h3 {
  font-size: 24px;
}

.ie-div-position-customer-chat {
  display: none !important;
}

/*Inherited class for Blogs hidden */
.pagebuilder-mobile-hidden {
  display: none;
}

mavenoid-assistant {
  z-index: 20 !important;
}

div[data-name='ad-blog-box'],
div[builder-model='ad-blog-box'],
div[data-builder-component='ad-blog-box'],
div[data-builder-component='ad-blog-box'] div[builder-type='blocks'] {
  flex: 1;
  display: flex;
  width: 100%;
}

.affiliate-form input::placeholder {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

.affiliate-form input {
  border: 1px solid #000 !important;
  padding-left: 16px !important;
  border-radius: 5px !important;
}

.lens-form input::placeholder {
  text-align: left;
  font-weight: 300;
  font-size: 16px;
  color: #8b8d9b;
}

.lens-form input {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 1px solid #8b8d9b !important;
  padding-left: 0px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.plan-benefits li {
  background: url(/icons/tick-green.webp) no-repeat;
  list-style-type: none;
  padding-left: 30px;
  background-size: 20px;
  background-position-y: center;
}
